const slug = require("slug");
import axios from "axios";
import Router from "next/router";

export const verifyToken = async (token) => {
  let apiReturn = await axios
    .post(
      `${process.env.NEXT_PUBLIC_API_DOMAIN_URL}/wp-json/jwt-auth/v1/token/validate`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(function(response) {
      Router.push("/accueil");
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const fetchLastEditedFiches = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .get(`${process.env.NEXT_PUBLIC_API_NATEO}/last-edited-fiches`, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const fetchFiches = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .get(`${process.env.NEXT_PUBLIC_API_NATEO}/fiches`, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const fetchFichesToUpdate = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .get(`${process.env.NEXT_PUBLIC_API_NATEO}/fiches-to-update`, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const fetchPage = async (slug) => {
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .get(`${process.env.NEXT_PUBLIC_API_WP}/pages?slug=${slug}`, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const fetchFiche = async (id) => {
  if (undefined === id) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .get(`${process.env.NEXT_PUBLIC_API_NATEO}/fiche?id=${id}`, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const createFiche = async (formData) => {
  if (undefined === formData) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/add-fiche`, formData, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const deleteFiche = async (id) => {
  if (undefined === id) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(
      `${process.env.NEXT_PUBLIC_API_NATEO}/delete-fiche`,
      { id: id },
      { headers: { Authorization: `Bearer ${token?.token}` } }
    )
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const uploadFile = async (file, id, uploadFileType) => {
  
  if (undefined === id) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let formData = new FormData();
  formData.append("file", file);
  formData.append("post", id);
  formData.append("caption", uploadFileType);

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_WP}/media`, formData, {
      headers: {
        "Content-Type": file.type,
        "Content-Disposition": `form-data; filename=${slug(file.name)}`,
        Authorization: `Bearer ${token?.token}`,
      },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const deleteFile = async (id) => {
  if (undefined === id) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .delete(`${process.env.NEXT_PUBLIC_API_WP}/media/${id}?force=false`, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 410) {
        if('rest_already_trashed' === error.response.data.code) {
          // Faire un retour pour vider le champ ? 
          return error;
        }
      }
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const fetchAllFonds = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .get(`${process.env.NEXT_PUBLIC_API_NATEO}/fonds`, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const addFond = async (title) => {
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(
      `${process.env.NEXT_PUBLIC_API_NATEO}/fonds/new`,
      {
        title: title,
      },
      {
        headers: { Authorization: `Bearer ${token?.token}` },
      }
    )
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const submitFM = async (formData) => {
  if (undefined === formData) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/submit-fm`, formData, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const submitRCCI = async (formData) => {
  if (undefined === formData) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/submit-rcci`, formData, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const submitDirigeant = async (formData) => {
  if (undefined === formData) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/submit-dirigeant`, formData, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const valid = async (formData) => {
  if (undefined === formData) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/validation`, formData, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const reject = async (formData) => {
  if (undefined === formData) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/reject`, formData, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const archive = async (id) => {
  if (undefined === id) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/archive`, {post_id: id}, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const editRejected = async (id) => {
  if (undefined === id) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/edit-rejected`, {post_id: id}, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const actualisation = async (id) => {
  if (undefined === id) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/actualisation`, {post_id: id}, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};

export const comment = async (formData) => {
  if (undefined === formData) {
    return;
  }
  const token = JSON.parse(localStorage.getItem("token"));

  let apiReturn = await axios
    .post(`${process.env.NEXT_PUBLIC_API_NATEO}/comment`, formData, {
      headers: { Authorization: `Bearer ${token?.token}` },
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      if (error.response?.status === 403) {
        Router.push("/login");
      }
    });

  return apiReturn;
};
